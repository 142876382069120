<template>
  <div class="steps-wrapper" :class="{ closed: closeNav }">
    <div class="flex justify-between gap-10 items-start">
      <h2>
        <span v-if="projectTitle && !closeNav">{{ projectTitle.Name }}</span>
      </h2>
      <div
        @click="toggleProgressBar"
        class="flex cursor-pointer steps-wrapper__close"
      >
        <icon-base
          :width="11"
          :height="11"
          :viewBox1="6"
          :viewBox2="10"
          iconName="arrow"
          iconStroke="transparent"
          iconColor="transparent"
          ><icon-arrow
        /></icon-base>
      </div>
    </div>

    <ul class="steps-wrapper__nav">
      <li
        v-for="(step, index) in steps"
        :key="step.index"
        @click="moveStep(step)"
        :id="'authoringTab' + index"
        class="steps-wrapper__nav-item"
        :class="{
          'steps-wrapper__nav-item--in-progress': stepIndex === step.index,
          'steps-wrapper__nav-item--completed': stepIndex > step.index,
          'steps-wrapper__nav-item--incompleted': stepIndex < step.index,
          'steps-wrapper__nav-item--half-width': steps.length === 4,
        }"
      >
        <div class="steps-wrapper__nav-item-icon">
          <icon-base
            :width="step.icon.width"
            :height="step.icon.height"
            :viewBox1="step.icon.viewBox1"
            :viewBox2="step.icon.viewBox2"
            :iconName="step.icon.name"
            :iconColor="step.icon.color"
            :iconStroke="step.icon.iconStroke"
            ><component :is="step.icon.name"></component
          ></icon-base>
        </div>
        <span class="steps-wrapper__nav-item-title">
          <icon-base
            :width="step.icon.width"
            :height="step.icon.height"
            :viewBox1="step.icon.viewBox1"
            :viewBox2="step.icon.viewBox2"
            :iconName="step.icon.name"
            :iconColor="step.icon.color"
            :iconStroke="step.icon.iconStroke"
            ><component :is="step.icon.name"></component
          ></icon-base>
          {{ step.title }}</span
        >
        <div
          class="steps-wrapper__nav-sub-item"
          :class="{
            'is-active':
              ((step.id === 2 && stepsType !== 'map-maker') ||
                (step.id === 3 && stepsType === 'map-maker')) &&
              progressBarStatus[index],
          }"
          v-for="(item, index) in step.subItems"
          :key="index"
        >
          <i
            class="circle-loader"
            v-if="loadingSubItems && stepIndex === step.index"
          ></i>
          <font-awesome-icon icon="check" />{{ item }}
        </div>
      </li>
    </ul>
    <div class="steps-wrapper__info">
      <i class="steps-wrapper__info-icon">
        <icon-base
          :width="34"
          :height="34"
          :viewBox1="34"
          :viewBox2="34"
          iconName="dollar"
          ><icon-dollar
        /></icon-base>
      </i>
      <div>
        <span
          >Starting From
          <div class="ml-10" v-if="conciergeAssistance">
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >Total price is a dynamic calculation that ensures a readable
                  sample base for fully assessing your pack designs and
                  communication objectives. This price is impacted by number of
                  legs, number of deep dives and the specific category.
                  <span
                    v-if="priceInfoContent !== null && priceInfoContent !== ''"
                    >For more information, please follow
                    <u @click="totalPriceModal = true" class="cursor-pointer"
                      >this link</u
                    >.</span
                  >
                </span>
              </template>

              <a-icon type="info-circle" />
            </a-tooltip></div
        ></span>
        <div>
          <i
            v-if="showPriceLoader && stepsType !== 'pack'"
            class="circle-loader"
          ></i>
          <i v-else-if="getPriceLoader" class="circle-loader"></i>

          <div id="bottomNavPrice" v-else>
            <template>
              <p v-if="$route.meta.hidePrice">/</p>
              <p v-else>
                ${{
                  stepsType === "pack" ||
                  stepsType === "pack-sbd" ||
                  stepsType === "map-maker"
                    ? formatPrice(getPackTestPrice)
                    : formatPrice(getTotalPrice)
                }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="steps-wrapper__info steps-wrapper__info--feasibility"
      :class="{
        'steps-wrapper__info--feasibility-done': getFeasibilitySuccess,
      }"
      v-if="user && stepsType === 'pack' && user.SkinIdentifier === 'pg'"
    >
      <i class="steps-wrapper__info-icon">
        <icon-base
          v-if="!getFeasibilitySuccess"
          :width="24"
          :height="24"
          :viewBox1="24"
          :viewBox2="24"
          iconName="alert"
          ><icon-alert
        /></icon-base>
        <icon-base
          v-else
          :width="24"
          :height="24"
          :viewBox1="24"
          :viewBox2="24"
          iconName="check"
          iconStroke="#fff"
          ><icon-check
        /></icon-base>
      </i>
      <div>
        <span>Feasibility</span>
        <div>
          <p v-if="getCheckingFeasibilityLoader" class="flex items-center">
            Checking...
          </p>
          <p v-if="getFeasibilitySuccess && !getCheckingFeasibilityLoader">
            <font-awesome-icon icon="check" />
            Fully Feasible
          </p>
          <p
            v-if="
              getFeasibilitySuccess === false && !getCheckingFeasibilityLoader
            "
          >
            Not confirmed.
            <u @click="conciergeModal = true" class="cursor-pointer">
              Contact Us</u
            >
          </p>
        </div>
      </div>
    </div>

    <div class="steps-wrapper__ppt" v-if="false">
      <a-button type="primary" class="mb-10"
        ><icon-base
          :width="39"
          :height="39"
          :viewBox1="39"
          :viewBox2="39"
          iconName="download"
          iconStroke="#fff"
          iconColor="transparent"
          ><icon-download
        /></icon-base>
        Download Full Results Package
      </a-button>
      <p class="font-size-14">*PPT and Excel Files included.</p>
    </div>

    <a-modal
      title="Total price"
      :visible="totalPriceModal"
      @cancel="totalPriceModal = false"
      :footer="null"
      :width="550"
    >
      <p v-html="priceInfoContent"></p>
      <div class="flex justify-end">
        <a-button @click="totalPriceModal = false">Cancel</a-button>
      </div>
    </a-modal>

    <a-modal
      title="Concierge Assistance"
      :visible="conciergeModal"
      @cancel="conciergeModal = false"
      :afterClose="closeConciergeModal"
      :footer="null"
      :width="350"
      class="concierge-modal"
    >
      <p class="mb-20">
        If you'd like, you can hand off the configuration of your study to an
        expert from the Hotspex team! Please provide a couple of details about
        your project and an explanation of the research objectives.
      </p>
      <a-row
        :gutter="16"
        v-if="!conciergeModalLoading && !conciergeRequestSent"
      >
        <a-col :span="24" class="large-field">
          <div class="flex items-center">
            <label class="lineLable">Number of SKUs</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of packs that will be changing on each
                  shelf.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-input-number
            class="fullWidth"
            size="large"
            type="number"
            :min="1"
            :max="100000"
            v-model="conciergeSKUs"
            placeholder="Insert number of SKUs"
            :class="{
              'error-field':
                conciergeSKUs === null && formValidations.length > 0,
            }"
        /></a-col>
        <a-col :span="24" class="large-field">
          <div class="flex items-center">
            <label class="lineLable">Number of tests</label>
            <a-tooltip placement="top" overlayClassName="ant-tooltip--step-nav">
              <template slot="title">
                <span
                  >This is the number of legs in your study
                  <strong>including Control</strong>.</span
                >
              </template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            placeholder="Select number of tests"
            :key="Math.random()"
            @change="conciergeVersions = $event"
            :default-value="conciergeVersions || undefined"
            :class="{
              'error-field':
                conciergeVersions === null && formValidations.length > 0,
            }"
          >
            <a-select-option
              v-for="(item, index) in [1, 2, 3, 4, '5+']"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select></a-col
        >
      </a-row>

      <div
        v-else
        class="concierge-modal__info flex items-center justify-center"
      >
        <Loader
          v-if="conciergeModalLoading"
          text="Please wait, your data will be saved soon..."
          contentPosition="vertical"
        ></Loader>
        <div v-else class="concierge-modal__info-success">
          <p>Your request is successfully sent.</p>
          <a href="#">Go to Dashboard</a>
        </div>
      </div>
      <div class="concierge-modal__chat flex items-center flex-col">
        <p><span>OR</span></p>
        <div>
          <a
            target="_blank"
            class="btn-teams-chat flex items-center justify-center"
            href="https://teams.microsoft.com/l/message/19:WwjKEmUXIAbR-bwR18w8rTSSCYHV8TOrFH440_LnnlM1@thread.tacv2/1689246846667?tenantId=1ca0aff4-f44d-4645-8f6d-b49dd858ae14&groupId=a8180bd2-6ee9-410e-8c41-68ddfc46f7b1&parentMessageId=1689246846667&teamName=P%26G S365 Support&channelName=&createdTime=1689246846667"
            ><i></i>Live Chat</a
          >
        </div>
      </div>
      <a-row :gutter="16" class="mt-10">
        <a-col :span="12">
          <ul
            class="validation-list validation-list--basic"
            v-if="formValidations.length > 0"
          >
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            class="flex items-center mr-10"
            @click="conciergeModal = false"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveConcierge"
            :disabled="conciergeModalLoading || conciergeRequestSent"
          >
            Submit
          </a-button></a-col
        >
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { mutations, store } from "@/utils/store.js";
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import priceFormating from "@/mixins/priceFormating.js";
import Loader from "@/components/general/Loader.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconDollar from "@/components/icons/IconDollar.vue";
import IconAlert from "@/components/icons/IconAlert.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconAdjustments from "@/components/icons/IconAdjustments.vue";
import IconChartHistogram from "@/components/icons/IconChartHistogram.vue";
import IconBulb from "@/components/icons/IconBulb.vue";
import IconQuestion from "@/components/icons/IconQuestion.vue";
import IconRocket from "@/components/icons/IconRocket.vue";
import IconCreative from "@/components/icons/IconCreative.vue";
import IconPie from "@/components/icons/IconPie.vue";
import IconMetrics from "@/components/icons/IconMetrics.vue";
import IconAudience from "@/components/icons/IconAudience.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import IconArrow from "@/components/icons/IconArrow.vue";
import IconUsersQuotas from "@/components/icons/IconUsersQuotas.vue";
import IconCategoryInputs from "@/components/icons/IconCategoryInputs.vue";

export default {
  name: "ProgressBar",
  components: {
    Loader,
    IconBase,
    IconDollar,
    IconAlert,
    IconCheck,
    IconAdjustments,
    IconChartHistogram,
    IconBulb,
    IconQuestion,
    IconRocket,
    IconCreative,
    IconPie,
    IconMetrics,
    IconAudience,
    IconDownload,
    IconArrow,
    IconUsersQuotas,
    IconCategoryInputs,
  },
  mixins: [surveyPriceMixin, priceFormating],
  props: {
    stepIndex: {
      type: Number,
      default() {
        return 1;
      },
    },
    stepsType: {
      type: String,
      default() {
        return "video";
      },
    },
    surveyID: [Number, String],
    conciergeAssistance: {
      type: Boolean,
      default() {
        return false;
      },
    },
    progressBarStatus: {
      type: Array,
      default() {
        return [false, false, false, false, false];
      },
    },
  },
  data() {
    return {
      surveyTypeID: null,
      videoTestSteps: [
        {
          title: "General Settings",
          subItems: ["General Study information"],
          icon: "IconCheck",
          index: 1,
          route: "vid-setting",
        },
        {
          title: "Creative",
          subItems: ["Creative"],
          icon: "IconCheck",
          index: 2,
          route: "vid-creative",
        },
        {
          title: "Brand Strategy",
          subItems: ["Brand Strategy"],
          icon: "IconCheck",
          index: 3,
          route: "vid-brand-strategy",
        },
        {
          title: "Key Metrics",
          subItems: ["Key Metrics"],
          icon: "IconCheck",
          index: 4,
          route: "vid-key-metrics",
        },
        {
          title: "Audience",
          subItems: ["Audience"],
          icon: "IconCheck",
          index: 5,
          route: "vid-audience",
        },
        {
          title: "Launch",
          subItems: ["Launch"],
          icon: "IconCheck",
          index: 6,
          route: "vid-launch",
        },
      ],
      packTestSteps: [
        {
          id: 1,
          title: "General Settings",
          subItems: ["General Study information"],
          icon: {
            name: "IconAdjustments",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 1,
          route: "pack-test-setting",
        },
        {
          id: 2,
          title: "Stimuli",
          subItems: [
            "Select Shelf",
            "Select Test Products",
            "Upload Test Images",
            "Identify Priority Products",
            "Define Superiority Benchmark",
          ],
          icon: {
            name: "IconChartHistogram",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 2,
          route: "stimuli",
        },
        {
          id: 3,
          title: "Brand Strategy",
          subItems: ["Emotional Attributes"],
          icon: {
            name: "IconBulb",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 3,
          route: "communication-objective",
        },
        {
          id: 4,
          title: "Custom questions",
          subItems: ["Add custom questions"],
          icon: {
            name: "IconQuestion",
            width: "32",
            height: "33",
            viewBox1: "24",
            viewBox2: "24",
            color: "transparent",
          },
          index: 4,
          route: "custom-questions",
        },
        {
          id: 5,
          title: "Launch",
          subItems: ["Summary & Confirmation"],
          icon: {
            name: "IconRocket",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 6,
          route: "pack-test-launch",
        },
      ],
      packTestSbdSteps: [
        {
          id: 1,
          title: "General Settings",
          subItems: ["General Study information"],
          icon: {
            name: "IconAdjustments",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 1,
          route: "pack-test-setting",
        },
        {
          id: 2,
          title: "Stimuli",
          subItems: [
            "Select Shelf",
            "Select Test Products",
            "Upload Test Images",
            "Identify Priority Products",
            "Define Superiority Benchmark",
          ],
          icon: {
            name: "IconChartHistogram",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 2,
          route: "stimuli",
        },
        {
          id: 3,
          title: "Brand Strategy",
          subItems: ["Functional Associations"],
          icon: {
            name: "IconBulb",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 3,
          route: "communication-objective",
        },
        {
          id: 4,
          title: "Category Inputs",
          subItems: ["Add category inputs"],
          icon: {
            name: "IconCategoryInputs",
            width: "32",
            height: "33",
            viewBox1: "24",
            viewBox2: "24",
            color: "#4318FF",
          },
          index: 4,
          route: "pack-sbd-category-inputs",
        },

        {
          id: 5,
          title: "Quotas",
          subItems: ["Configure survey quotas"],
          icon: {
            name: "IconUsersQuotas",
            width: "32",
            height: "33",
            viewBox1: "485",
            viewBox2: "485",
            color: "#4318FF",
          },
          index: 5,
          route: "quotas",
        },
        {
          id: 6,
          title: "Launch",
          subItems: ["Summary & Confirmation"],
          icon: {
            name: "IconRocket",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 6,
          route: "pack-test-launch",
        },
      ],
      mapMakerSteps: [
        {
          id: 1,
          title: "General Settings",
          subItems: ["General Study information"],
          icon: {
            name: "IconAdjustments",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 1,
          route: "pack-test-setting",
        },
        {
          id: 2,
          title: "Category Inputs",
          subItems: ["Add category inputs"],
          icon: {
            name: "IconCategoryInputs",
            width: "32",
            height: "33",
            viewBox1: "24",
            viewBox2: "24",
            color: "#4318FF",
          },
          index: 2,
          route: "pack-sbd-category-inputs",
        },
        {
          id: 3,
          title: "Stimuli",
          subItems: ["Select Brands", "Upload Images"],
          icon: {
            name: "IconChartHistogram",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 3,
          route: "stimuli",
        },
        {
          id: 4,
          title: "Launch",
          subItems: ["Summary & Confirmation"],
          icon: {
            name: "IconRocket",
            width: "32",
            height: "33",
            viewBox1: "32",
            viewBox2: "33",
            color: "transparent",
          },
          index: 4,
          route: "pack-test-launch",
        },
      ],
      steps: [],
      user: null,
      showPriceLoader: true,
      priceInfoContent: null,
      totalPriceModal: false,
      conciergeModal: false,
      conciergeModalLoading: false,
      conciergeRequestSent: false,
      formValidations: [],
      conciergeSKUs: null,
      conciergeVersions: null,
      loadingSubItems: true,
      projectsType: [],
      closeNav: false,
      editableSurvey: null,
    };
  },
  computed: {
    getPriceLoader() {
      return store.priceLoader.length > 0;
    },
    getTotalPrice() {
      return store.surveyTotalPrice;
    },
    getPackTestPrice() {
      return store.surveyPackTestPrice;
    },
    getCheckingFeasibilityLoader() {
      return store.checkingFeasibilityLoader.length > 0;
    },
    getFeasibilitySuccess() {
      return store.feasibilitySuccess;
    },
    projectTitle() {
      return this.projectsType.find(
        (item) => item.TypeId === this.surveyTypeID
      );
    },
  },
  methods: {
    closeConciergeModal() {
      this.formValidations = [];
      this.conciergeModalLoading = false;
      this.conciergeRequestSent = false;
      this.conciergeSKUs = null;
      this.conciergeVersions = null;
    },
    saveConcierge() {
      this.formValidations = [];
      if (this.conciergeSKUs === null || this.conciergeVersions === null) {
        this.formValidations.push("All fields are required.");
      } else {
        this.conciergeModalLoading = true;
        this.sendConciergeRequest();
      }
    },
    sendConciergeRequest() {
      const conciergeRequest = new Promise((resolve) => {
        wsUtils.ConciergeRequest(
          {
            sucks: this.conciergeSKUs,
            versions: this.conciergeVersions,
            preferedContact: "Email",
            UserId: this.user.EncrypteduserID,
            SurveyID: this.$route.params.id,
          },
          resolve
        );
      });

      conciergeRequest.then((data) => {
        if (data.success) {
          setTimeout(() => {
            this.conciergeModalLoading = false;
            this.conciergeRequestSent = true;
          }, 1000);
        }
      });
    },
    getPriceInfoContent() {
      const GetPriceInfo = new Promise((resolve) => {
        wsUtils.GetPriceInfo(
          {
            accountId: this.user.AccountId,
          },
          resolve
        );
      });

      GetPriceInfo.then((data) => {
        this.priceInfoContent = data.PriceInfoText;
      });
    },
    getSurveyData() {
      let wsType;

      if (this.stepsType === "pack" || this.stepsType === "pack-sbd") {
        wsType = "GetPackTestSurveyDetails";
      } else if (this.stepsType === "Reticle Alignment Test") {
        wsType = "GetSurveyStimReticle";
      } else {
        wsType = "GetCombineSurveyDetails";
      }
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.surveyID,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);
      });
    },
    moveStep() {
      return;
      // let ssoLogin = jsUtils.readCookie("sso-login");
      // if (
      //   this.user.dashboardURL !== "" &&
      //   step.index === 1 &&
      //   ssoLogin !== ""
      // ) {
      //   let ssoData = JSON.parse(jsUtils.readCookie("sso-login"));
      //   window.open(
      //     `https://d11qiq41szmpd8.cloudfront.net/hotspex/project/${ssoData.pgprojectid}`,
      //     "_self",
      //     "noreferrer"
      //   );
      // } else {
      //   this.getSurveyData();
      //   this.$router.push({
      //     name: `${step.route}`,
      //     params: { id: this.surveyID },
      //   });
      // }
    },
    getProjectsType() {
      const projectsType = new Promise((resolve) => {
        wsUtils.SurveyTypeFilter(
          {
            accountId: this.user.EncryptedAccountId,
          },
          resolve
        );
      });

      projectsType.then((data) => {
        this.projectsType = data.filter((product) => product.Status === "1");
      });
    },
    toggleProgressBar() {
      this.closeNav = !this.closeNav;
      jsUtils.setCookie("nav-closed", this.closeNav, 3);
      mutations.setNavClosed(this.closeNav);
    },
    setSteps() {
      if (this.$route.meta.progressBarSteps) {
        this.steps = this.$route.meta.progressBarSteps;
        if (this.stepsType === "pack" && this.user.SkinIdentifier === "pg") {
          this.steps = this.packTestSteps.filter((step) => step.id !== 4);
        }
      } else if (this.stepsType === "video") {
        this.steps = this.videoTestSteps;
      } else if (
        this.stepsType === "pack" &&
        this.user.SkinIdentifier !== "pg"
      ) {
        this.steps = this.packTestSteps;
      } else if (
        this.stepsType === "pack" &&
        this.user.SkinIdentifier === "pg"
      ) {
        this.steps = this.packTestSteps.filter((step) => step.id !== 4);
      } else if (this.stepsType === "pack-sbd") {
        this.steps = this.packTestSbdSteps;
      } else if (this.stepsType === "map-maker") {
        this.steps = this.mapMakerSteps;
      } else if (this.stepsType === "brand-fingerprint") {
        this.steps = this.mapMakerSteps;
      }
    },
  },
  mounted() {
    this.user = jsUtils.getUserInfo();
    this.getPriceInfoContent();
    this.getProjectsType();
  },
  activated() {
    this.editableSurvey = jsUtils.getEditableSurvey();
    const navClosedCookie = jsUtils.readCookie("nav-closed");
    this.closeNav =
      navClosedCookie !== undefined ? navClosedCookie === "true" : false;
    mutations.setNavClosed(this.closeNav);
    this.loadingSubItems = true;
    mutations.setSurveyPrice(this.$route.params.id, "SurveyId");
    setTimeout(() => {
      this.loadingSubItems = false;
      this.showPriceLoader = false;
    }, 1500);
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.user = jsUtils.getUserInfo();
    this.setSteps();
  },

  created() {
    if (this.stepsType === "pack") {
      window.Hopscotch("tour.start", { tourId: 154 });
    }
  },
  watch: {
    stepsType() {
      this.setSteps();
    },
  },
};
</script>

<style scoped lang="scss">
$medium: 1100px;
.steps-wrapper {
  width: 280px;
  background: #fff;
  text-align: left;
  padding: 30px 22px;
  margin-right: 38px;
  position: fixed;
  z-index: 998;
  left: 96px;
  top: 0;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &.closed {
    width: 128px;
  }
  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 30px;
  }
  &__close {
    padding: 5px;
    transform: rotate(180deg);
    svg {
      transition: transform 0.3s ease;
    }
    &:hover {
      svg {
        transform: translateX(2px);
      }
    }
    .closed & {
      transform: rotate(0deg);
    }
  }
  &__nav {
    margin: 0 0 auto;
    padding: 0;
    counter-reset: item-counter;
    position: relative;
    display: flex;
    flex-direction: column;

    .closed & {
      padding-left: 12px;
      margin-top: 25px;
    }
  }
  &__nav-item {
    color: #bbbbbb;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    position: relative;
    padding-bottom: 30px;
    padding-left: 36px;
    .steps-wrapper__nav-item-title {
      svg[aria-labelledby="IconCreative"],
      ::v-deep svg[aria-labelledby="IconAudience"],
      ::v-deep svg[aria-labelledby="IconCategoryInputs"],
      ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
        path {
          fill: #bbbbbb;
        }
      }
      svg[aria-labelledby="IconAudience"],
      svg[aria-labelledby="IconCategoryInputs"] {
        stroke-width: 0;
      }
      ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
        polygon {
          fill: #bbbbbb;
        }
      }
      ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
        width: 18px;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      content: counter(item-counter);
      counter-increment: item-counter;
      background-color: #bbbbbb;
      color: #fff;
      width: 30px;
      height: 30px;
      line-height: 26px;
      border-radius: 12px;
      font-weight: bold;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      border: 2px solid #fff;
      font-size: 17px;
      z-index: 1;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 15px;
      height: 100%;
      border-right: 1px solid #1c1c1c4d;
      content: "";
    }
    .closed & {
      height: 100px;
      &:after {
        border-width: 2px;
        left: 30px;
      }
      &:before {
        width: 61px;
        height: 61px;
        border-width: 8px;
        border-radius: 20px;
        font-size: 0;
      }
    }
    &--completed {
      color: #cfc5f0;
      &:before {
        background-color: #cfc5f0;
      }
      &:after {
        border-color: #cfc5f0;
      }
      .steps-wrapper__nav-item-title {
        svg[aria-labelledby="IconCreative"],
        ::v-deep svg[aria-labelledby="IconAudience"],
        ::v-deep svg[aria-labelledby="IconUsersQuotas"],
        ::v-deep svg[aria-labelledby="IconCategoryInputs"] {
          path {
            fill: #cfc5f0;
          }
        }
      }

      ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
        polygon {
          fill: #bbbbbb;
        }
      }

      .steps-wrapper__nav-sub-item {
        &:after {
          border-color: #cfc5f0;
          background: #cfc5f0;
        }
        svg {
          display: block;
        }
      }
    }
    &--in-progress {
      color: var(--blue);
      &:before {
        background-color: var(--blue);
      }
      .steps-wrapper__nav-sub-item {
        &:after {
          border-color: var(--blue);
        }
      }
      .steps-wrapper__nav-item-title {
        svg[aria-labelledby="IconCreative"],
        ::v-deep svg[aria-labelledby="IconAudience"],
        ::v-deep svg[aria-labelledby="IconCategoryInputs"],
        ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
          path {
            fill: var(--blue);
          }
        }
        ::v-deep svg[aria-labelledby="IconUsersQuotas"] {
          polygon {
            fill: var(--blue);
          }
        }
      }
    }
  }
  &__nav-item-icon {
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 1;
    color: #fff;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: none;
    svg {
      margin-bottom: 0;

      &[aria-labelledby="IconUsersQuotas"] {
        width: 24px;
      }

      &[aria-labelledby="IconCreative"] path,
      &[aria-labelledby="IconUsersQuotas"] ::v-deep path,
      &[aria-labelledby="IconUsersQuotas"] ::v-deep polygon {
        fill: #fff !important;
      }
      &[aria-labelledby="IconAudience"] ::v-deep path,
      &[aria-labelledby="IconCategoryInputs"] ::v-deep path {
        fill: #fff !important;
        stroke-width: 0;
      }
      &[aria-labelledby="IconPie"] {
        margin-left: -3px;
        margin-top: -2px;
      }
    }
    .closed & {
      display: flex;
    }
  }
  &__nav-item-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    display: block;

    .closed & {
      display: none;
    }

    svg {
      width: 21px;
      height: 21px;
      margin-right: 4px;
      transform: translateY(3px);
      &[aria-labelledby="IconPie"] {
        margin-left: -3px;
      }
    }
  }
  &__nav-sub-item {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    display: block;
    position: relative;
    padding-left: 17px;
    &:after {
      position: absolute;
      left: 0;
      top: 3px;
      width: 14px;
      height: 14px;
      border: 2px solid #bbbbbb;
      border-radius: 5px;
      content: "";
    }
    .closed & {
      display: none;
    }
    svg {
      font-size: 6px;
      position: absolute;
      color: #fff;
      left: 4px;
      top: 7px;
      z-index: 1;
      display: none;
    }
    &.is-active {
      &:after {
        border-color: var(--blue);
        background: var(--blue);
      }
      svg {
        display: block;
      }
    }

    .circle-loader {
      position: absolute;
      left: -1px;
      z-index: 1;
      background: #fff;
      width: 16px;
      height: 16px;
      top: 2px;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 60px;
    color: var(--blue);
    &:last-child {
      margin-bottom: 0;
    }

    .closed & {
      display: none;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      display: flex;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.4px;
      margin: 0;
      min-height: 24px;
    }
    u {
      font-size: 14px;
    }
    &--feasibility {
      color: #f78225;
      .steps-wrapper__info-icon {
        background: #f78225;
      }
    }
    &--feasibility-done {
      color: #00bd4c;
      .steps-wrapper__info-icon {
        background: #00bd4c;
      }
    }
  }
  &__info-icon {
    display: flex;
    min-width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var(--blue);
    align-items: center;
    justify-content: center;
    margin-right: 18px;
  }
  &__ppt {
    .ant-btn {
      height: 103px;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 28px;
      white-space: normal;
      text-align: left;
      box-shadow: 0 1px 2px 4px rgba(16, 24, 40, 0.1) !important;
      svg {
        min-width: 39px;
        margin-right: 8px;
      }
    }
    p {
      opacity: 0.5;
      margin: 0;
      font-weight: 500;
    }
  }
}

.concierge-modal {
  &__info {
    height: 237px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    font-size: 12px;
  }
  &__info-success {
    text-align: center;
    p {
      font-size: 18px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0ZM8 15l-5-5 1.4-1.4L8 12.2l7.6-7.6L17 6l-9 9Z' fill='%2329dbb7' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat center 0 #fff;
      background-size: 26px;
      padding-top: 30px;
    }
    a {
      font-size: 14px;
      text-decoration: underline;
      color: var(--hsblue);
    }
  }
  &__chat {
    margin-bottom: 21px;
    padding-bottom: 34px;
    border-bottom: 1px solid #f0f0f0;
    p {
      position: relative;
      text-align: center;
      width: 100%;
      &::before {
        position: absolute;
        left: 0;
        width: 100%;
        top: 10px;
        content: "";
        border-bottom: 1px solid #f0f0f0;
      }
      span {
        background: #fff;
        z-index: 1;
        position: relative;
        padding: 0 7px;
      }
    }
  }
}
</style>
